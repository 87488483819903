import { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import MaskedInput from "react-text-mask";
import deckMolhadoImg from "../../assets/deck-molhado.png";
import labelFormHead from "../../assets/logo-living.png";
import { api_prod } from "../../services/api";
import { Container } from "./styles";

export function SectionFormulario() {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [formSend, setFormSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);

  /*   function openDialog() {
    const dialog = document.querySelector('dialog')
    dialog.showModal()
    document.style.display = 'flex'
   
  } */

  function handleSubmit(event) {
    setLoading(true);
    setFormSend(false);
    event.preventDefault();
    if (nome && email && telefone) {
      /*  api
        .post("empreendimento/contato/store", {
          nome,
          email,
          telefone,
          // empreendimento_id: 2, // Lux Design Concept
          empreendimento_id: 4, // Lux Design Living
          // empreendimento_id: 5, // Piazza by Quadra
        })
        .then(function (response) {
          console.log(response.data);
          setFormSend(true);
          setLoading(false);
          setAlertMsg(false);
        })
        .catch(function (error) {
          console.error(error);
        }); */
      api_prod
        .get(
          `/empreendimentos/add-subscriber?venture_id=4&phone=${telefone}&email=${email}&name=${nome}&checkbox=true`,
          {
            venture_id: 4,
            phone: telefone,
            email: email,
            name: nome,
            checkbox: true,

            // empreendimento_id: 2, // Lux Design Concept
            // empreendimento_id: 4, // Lux Design Living
          }
        )
        .then(function (response) {
          console.log(response.data);
          setFormSend(true);
          setLoading(false);
          setAlertMsg(false);
          /*  openDialog() */
          // window.location.assign(response.data.results.url);
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      handleSumitOver();
    }
    setNome("");
    setEmail("");
    setTelefone("");
  }

  function handleSumitOver() {
    setAlertMsg(true);
  }

  return (
    <Container>
      <img src={deckMolhadoImg} alt="" className="background" />
      <form
        className="section-formulario-form"
        id="form-main-venture"
        onSubmit={handleSubmit}
      >
        <div className="header">
          <div>
            <h3>Interessado no Imóvel?</h3>
            <p>Entre em contato conosco e tire suas dúvidas</p>
          </div>
          <img src={labelFormHead} alt="" />
        </div>
        <div className="content-inputs">
          <ScrollAnimation animateIn="animate__fadeInDown">
            <label htmlFor="">Nome</label>
            <input
              type="text"
              placeholder="Informe seu nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInDown">
            <label htmlFor="">E-mail</label>
            <input
              type="email"
              placeholder="Informe seu e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </ScrollAnimation>

          <ScrollAnimation animateIn="animate__fadeInDown">
            <label htmlFor="">Número Telefone/Whatsapp</label>
            <MaskedInput
              className="input-theme"
              placeholder="(91) 99999-9999"
              mask={[
                "(",
                /[1-9]/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              value={telefone}
              onChange={(e) => setTelefone(e.target.value)}
            />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInDown">
            {loading ? (
              <input type="submit" value="Enviando sua mensagem" />
            ) : (
              <input type="submit" value="Quero mais informações" />
            )}
          </ScrollAnimation>

          {formSend && (
            <p className="text-dark mt-4">
              Mensagem enviada, retornaremos em breve.
            </p>
          )}
          {alertMsg && (
            <p className="text-dark mt-4">
              Por favor, preencha todos os campos.
            </p>
          )}
        </div>
      </form>
    </Container>
  );
}
